$img: "../assets/images/";

$white: #fff;
$black: #000;
$offwhite: #f5f5f5;
$purple: #9841ff;

// Text section
::-moz-selection {
  color: $offwhite;
  background: $purple;
}
::selection {
  color: $offwhite;
  background: $purple;
}

// Cursor extend
.cursor-default {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:24px;'><text y='50%'>✨</text></svg>")
      26 0,
    auto;
}
.cursor-hover {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>👆🏾</text></svg>")
      26 0,
    auto;
}
.cursor-hover-home {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>💅🏾</text></svg>")
      26 0,
    auto;
}
.cursor-hover-insta {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>💁🏽‍♀️</text></svg>")
      26 0,
    auto;
}
.cursor-hover-behance {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>🖼️</text></svg>")
      26 0,
    auto;
}
.cursor-hover-linkedin {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>🖇️</text></svg>")
      26 0,
    auto;
}
.cursor-hover-email {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>💌</text></svg>")
      26 0,
    auto;
}

@function responsive($value1, $value2, $width1: 320, $width2: 1440) {
  $x1: $width1;
  $x2: $width2;
  $y1: $value1;
  $y2: $value2;
  $a: ($y2 - $y1) / ($x2 - $x1);
  $b: ($x2 * $y1 - $x1 * $y2) / ($x2 - $x1);
  @return calc(#{$a * 100vw} + #{$b * 1px});
}
