$fonts: "../assets/fonts/";

@font-face {
  font-family: VCNudge;
  src: url($fonts + "VCNudgeNormal-Medium.eot");
  src: url($fonts + "VCNudgeNormal-Medium.eot?#iefix")
      format("embedded-opentype"),
    url($fonts + "VCNudgeNormal-Medium.ttf") format("truetype"),
    url($fonts + "VCNudgeNormal-Medium.svg#VCNudge-Regular") format("svg"),
    url($fonts + "VCNudgeNormal-Medium.woff") format("woff"),
    url($fonts + "VCNudgeNormal-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VCNudgeMedium;
  src: url($fonts + "VCNudge-Medium.eot");
  src: url($fonts + "VCNudge-Medium.eot?#iefix") format("embedded-opentype"),
    url($fonts + "VCNudge-Medium.ttf") format("truetype"),
    url($fonts + "VCNudge-Medium.svg#VCNudge-Medium") format("svg"),
    url($fonts + "VCNudge-Medium.woff") format("woff"),
    url($fonts + "VCNudge-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
