* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  color: $black;
  line-height: 1.48;
  font-size: responsive(20, 44);
  font-weight: 500;
  letter-spacing: 0.16px;
  font-family: VCNudgeMedium, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: inherit;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  background-color: transparent;
  border: none;
  outline: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1;
  cursor: pointer;
}
