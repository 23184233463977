@charset "UTF-8";
::-moz-selection {
  color: #f5f5f5;
  background: #9841ff; }

::selection {
  color: #f5f5f5;
  background: #9841ff; }

.cursor-default, body {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:24px;'><text y='50%'>✨</text></svg>") 26 0, auto; }

.cursor-hover, .wrap .container .content a, .wrap .container .btns .btn {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>👆🏾</text></svg>") 26 0, auto; }

.cursor-hover-home {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>💅🏾</text></svg>") 26 0, auto; }

.cursor-hover-insta {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>💁🏽‍♀️</text></svg>") 26 0, auto; }

.cursor-hover-behance {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>🖼️</text></svg>") 26 0, auto; }

.cursor-hover-linkedin {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>🖇️</text></svg>") 26 0, auto; }

.cursor-hover-email {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='58' viewport='0 0 100 100' style='font-size:34px;'><text y='50%'>💌</text></svg>") 26 0, auto; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  scroll-behavior: smooth; }

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  color: #000;
  line-height: 1.48;
  font-size: calc(2.14286vw + 13.14286px);
  font-weight: 500;
  letter-spacing: 0.16px;
  font-family: VCNudgeMedium, sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: inherit;
  margin: 0;
  padding: 0; }

a {
  color: inherit;
  text-decoration: none; }

ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

button {
  background-color: transparent;
  border: none;
  outline: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1;
  cursor: pointer; }

@font-face {
  font-family: VCNudge;
  src: url("../assets/fonts/VCNudgeNormal-Medium.eot");
  src: url("../assets/fonts/VCNudgeNormal-Medium.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/VCNudgeNormal-Medium.ttf") format("truetype"), url("../assets/fonts/VCNudgeNormal-Medium.svg#VCNudge-Regular") format("svg"), url("../assets/fonts/VCNudgeNormal-Medium.woff") format("woff"), url("../assets/fonts/VCNudgeNormal-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: VCNudgeMedium;
  src: url("../assets/fonts/VCNudge-Medium.eot");
  src: url("../assets/fonts/VCNudge-Medium.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/VCNudge-Medium.ttf") format("truetype"), url("../assets/fonts/VCNudge-Medium.svg#VCNudge-Medium") format("svg"), url("../assets/fonts/VCNudge-Medium.woff") format("woff"), url("../assets/fonts/VCNudge-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

header .menu {
  top: 0; }

footer .menu {
  bottom: 0; }

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(2.5vw + 16px);
  position: fixed;
  z-index: 2;
  width: 100%; }
  .menu-item {
    font-weight: normal;
    font-size: calc(1.42857vw + 11.42857px);
    background-color: #fff; }

.wrap {
  padding: calc(7.14286vw + 57.14286px) calc(5vw + 8px) 80px;
  display: grid;
  gap: 0 20px;
  grid-template-columns: repeat(12, 1fr); }
  @media screen and (max-width: 700px) {
    .wrap {
      grid-template-columns: repeat(4, 1fr); } }
  .wrap .container {
    grid-column: span 12; }
    @media screen and (max-width: 700px) {
      .wrap .container {
        grid-column: span 4; } }
    .wrap .container .content p {
      overflow: visible;
      max-height: 700px;
      visibility: visible;
      margin-bottom: calc(1.42857vw + 27.42857px);
      transition: all 0.5s ease-in-out; }
      .wrap .container .content p.hidden {
        overflow: hidden;
        visibility: hidden;
        max-height: 0;
        margin: 0; }
    .wrap .container .content a {
      text-decoration: underline; }
    .wrap .container .content img {
      vertical-align: bottom;
      transform: scale(1);
      transition: transform 0.4s ease-in-out;
      border-radius: 0.25rem;
      position: relative;
      height: calc(3.03571vw + 20.28571px);
      width: auto; }
      .wrap .container .content img:hover {
        transform: scale(4);
        z-index: 1; }
    .wrap .container .btns {
      display: flex;
      gap: 40px; }
      .wrap .container .btns .btn {
        font-size: calc(0.71429vw + 13.71429px);
        text-decoration: underline;
        margin-bottom: 48px;
        display: block;
        width: fit-content; }
      .wrap .container .btns a.btn {
        display: none; }
        .wrap .container .btns a.btn.active {
          display: block; }
  .wrap .photo {
    display: flex;
    justify-content: center;
    grid-column: span 12; }
    @media screen and (max-width: 700px) {
      .wrap .photo {
        grid-column: span 4; } }
    .wrap .photo img {
      display: block;
      border-radius: 1rem;
      transform: rotate(15deg);
      overflow: hidden;
      width: calc(28.57143vw + 188.57143px);
      height: auto; }
